// function preventBack() { window.history.forward(); }
//         setTimeout("preventBack()", 0);
//         window.onunload = function () { null };
//     document.addEventListener("contextmenu", function(event){
//     event.preventDefault();
//     alert('Right Click is Disabled');    
//     }, false);
  
//     document.onselectstart = () => {
//       event.preventDefault();
//       alert('Disabled copy Paste');
//       }
      
//       document.onkeydown = function(e) {
//         if(event.keyCode == 123) {
//           return false;
//         }
//         if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
//           return false;
//         }
//         if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
//           return false;
//         }
//         if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
//           return false;
//         }
//         if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
//           return false;
//         }
//         if (e.ctrlKey && e.key == 'p') {
//             alert('This section is not allowed to print or export to PDF');
//             e.cancelBubble = true;
//             e.preventDefault();
//             e.stopImmediatePropagation();
//         }
//       }

     

//       /** TO DISABLE PRINTS WHIT CTRL+P **/
//       document.addEventListener('keydown', (e) => {
//           if (e.ctrlKey && e.key == 'p') {
//               alert('This section is not allowed to print or export to PDF');
//               e.cancelBubble = true;
//               e.preventDefault();
//               e.stopImmediatePropagation();
//               return false;
//           }
//       });
//     //   window.addEventListener("hashchange", function (e) {  
//     //       console.log('backButton is pressed')

//     //       var r = confirm("Your Session Will Expire ? Click 'OK' to Exit Exam or 'Cancel' to Continue Exam");
//     //       if (r == true) {
//     //         return false;
           
//     //       } else {
//     //         return true;
//     //       }
        
      
       
//     // }) 